<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item label="部门名称" :required="true">
            <el-input v-model="form.departName" class="formInput" />
          </el-form-item>
          <el-form-item v-show="info.addOr === 'addRoot'" label="公司" :required="true">
            <el-select v-model="form.companyId" class="formInput w-100" filterable>
              <el-option v-for="(v,i) in company" :key="v.id" :value="v.id" :label="v.companyName" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { company, depart } from '@/api'

export default {
  name: 'Operate',
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      form: {
        departName: '',
        companyId: this.$admin?'':this.$companyId,
        parentId: ''
      },
      company: []
    }
  },
  created() {
    this.upd()
    this.getCompany()
  },
  methods: {
    upd() {
      if (this.info.addOr === 'upd') {
        this.$axiosReq(depart+ '/' + this.row.id, null, null, 'get').then(res => {
          this.form=res.data
        }).catch(res => {
          this.$message.error(res.msg)
        })
      }
    },
    getCompany() {
      this.$axiosReq(company, null, {
        pageSize:100000
      }, 'get').then(res => {
        this.company = res.data.content
      }).catch(e => {
      })
    },
    // 提交添加
    commit() {
      if (this.$textNull(this.form.departName)) {
        this.$message.error('请填写部门名称！')
        return
      } else if (this.info.addOr !== 'addSon'&&this.$admin) {
        if (this.$textNull(this.form.companyId)) {
          this.$message.error('请选择公司！')
          return
        }
      }
      if (this.info.addOr === 'addRoot') {
        this.form.parentId = 0
        this.$axiosReq(depart, null, this.form, 'post').then(res => {
          this.$message.success(res.msg)
          this.close()
          this.$emit('get-list')
        }).catch(res => {
          this.$message.error(res.msg)
        })
      } else if (this.info.addOr === 'addSon') {
        this.form.parentId = this.row.id
        this.form.companyId = this.row.companyId
        this.$axiosReq(depart, null, this.form, 'post').then(res => {
          this.$message.success(res.msg)
          this.close()
          this.$emit('get-list')
        }).catch(res => {
          this.$message.error(res.msg)
        })
      } else {
        this.$axiosReq(depart + '/' + this.row.id, null, this.form, 'put').then(res => {
          this.$message.success(res.msg)
          this.close()
          this.$emit('get-list')
        }).catch(res => {
          this.$message.error(res.msg)
        })
      }
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
