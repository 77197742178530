var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-container"},[(_vm.dialog.refresh)?_c('Operate',{attrs:{"dialog":_vm.dialog.show,"row":_vm.dialog.row,"company":_vm.dialog.company,"info":_vm.dialog.info},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"get-list":function($event){return _vm.getList()},"refresh":_vm.refreshDialog}}):_vm._e(),_c('el-card',[_c('el-row',[_c('el-col',{attrs:{"span":5}},[_c('el-input',{staticClass:"input searchInput",staticStyle:{"width":"200px"},attrs:{"size":"mini","suffix-icon":"el-icon-search","placeholder":"搜索"},on:{"input":_vm.searchInput},model:{value:(_vm.condition.search),callback:function ($$v) {_vm.$set(_vm.condition, "search", $$v)},expression:"condition.search"}})],1),(_vm.$admin)?_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("公司")]),_c('el-select',{staticClass:"input searchInput",attrs:{"clearable":"","filterable":"","size":"mini"},on:{"change":_vm.changeCondition},model:{value:(_vm.condition.companyId),callback:function ($$v) {_vm.$set(_vm.condition, "companyId", $$v)},expression:"condition.companyId"}},_vm._l((_vm.company),function(v,i){return _c('el-option',{key:v.id,attrs:{"value":v.id,"label":v.companyName}})}),1)],1):_vm._e()],1)],1),_c('el-card',{staticStyle:{"margin-top":"20px"}},[_c('el-row',{attrs:{"type":"flex","gutter":30}},[_c('el-col',[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',[(
                                        _vm.$hasBtn(
                                            'sys:permissions:org:depart:add'
                                        )
                                    )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.openDialog(
                                            {
                                                title: '添加一级部门',
                                                addOr: 'addRoot',
                                            },
                                            { id: _vm.condition.company }
                                        )}}},[_vm._v("添加一级部门 ")]):_vm._e(),_c('el-button',{attrs:{"type":"warning","size":"mini"},on:{"click":function($event){return _vm.$refs.table.setAllTreeExpand(true)}}},[_vm._v("展开所有")]),_c('el-button',{attrs:{"type":"info","size":"mini"},on:{"click":function($event){return _vm.$refs.table.clearTreeExpand()}}},[_vm._v("收起所有")])],1),_c('el-col',{attrs:{"span":1}},[_c('vxe-button',{on:{"click":function($event){return _vm.getList(_vm.condition.company)}}},[_vm._v("刷新")])],1)],1)]},proxy:true}])}),_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":"700","row-id":"id","tree-config":{
                        children: 'sons',
                        expandAll: true,
                        indent: 15,
                    },"data":_vm.dept,"loading":_vm.loading2}},[_c('vxe-table-column',{attrs:{"type":"seq","width":"60"}}),_c('vxe-table-column',{attrs:{"field":"departName","title":"部门名称","align":"left","tree-node":""}}),_c('vxe-table-column',{attrs:{"field":"companyName","title":"公司名称","align":"left"}}),_c('vxe-table-column',{attrs:{"field":"","title":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
                    var rowIndex = ref.rowIndex;
return [_c('el-dropdown',{attrs:{"size":"small","type":"primary"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" 操作"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                                            _vm.$hasBtn(
                                                'sys:permissions:org:depart:add'
                                            )
                                        )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-plus"},nativeOn:{"click":function($event){return _vm.openDialog(
                                                {
                                                    title: '添加下级',
                                                    addOr: 'addSon',
                                                },
                                                row
                                            )}}},[_vm._v(" 添加 ")]):_vm._e(),(
                                            _vm.$hasBtn(
                                                'sys:permissions:org:depart:edit'
                                            )
                                        )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-edit"},nativeOn:{"click":function($event){return _vm.openDialog(
                                                {
                                                    title: '修改名称',
                                                    addOr: 'upd',
                                                },
                                                row
                                            )}}},[_vm._v(" 修改 ")]):_vm._e(),(
                                            _vm.$hasBtn(
                                                'sys:permissions:org:depart:delete'
                                            )
                                        )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){return _vm.remove({ del: 'single' }, row)}}},[_vm._v("删除 ")]):_vm._e()],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }