<template>
    <div class="dashboard-container">
        <Operate
            v-if="dialog.refresh"
            :dialog.sync="dialog.show"
            :row="dialog.row"
            :company="dialog.company"
            :info="dialog.info"
            @get-list="getList()"
            @refresh="refreshDialog"
        />
        <el-card>
            <el-row>
                <el-col :span="5">
                    <el-input
                        style="width: 200px"
                        v-model="condition.search"
                        class="input searchInput"
                        size="mini"
                        suffix-icon="el-icon-search"
                        placeholder="搜索"
                        @input="searchInput"
                    />
                </el-col>
                <el-col v-if="$admin" :span="6">
                    <span class="text-primary text-pad-right">公司</span>
                    <el-select
                        v-model="condition.companyId"
                        class="input searchInput"
                        clearable
                        filterable
                        size="mini"
                        @change="changeCondition"
                    >
                        <el-option
                            v-for="(v, i) in company"
                            :key="v.id"
                            :value="v.id"
                            :label="v.companyName"
                        />
                    </el-select>
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 20px">
            <el-row type="flex" :gutter="30">
                <el-col>
                    <vxe-toolbar>
                        <template v-slot:buttons>
                            <el-row type="flex" justify="space-between">
                                <el-col>
                                    <el-button
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:depart:add'
                                            )
                                        "
                                        type="primary"
                                        size="mini"
                                        @click="
                                            openDialog(
                                                {
                                                    title: '添加一级部门',
                                                    addOr: 'addRoot',
                                                },
                                                { id: condition.company }
                                            )
                                        "
                                        >添加一级部门
                                    </el-button>
                                    <!--<el-button type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>-->
                                    <el-button
                                        type="warning"
                                        size="mini"
                                        @click="
                                            $refs.table.setAllTreeExpand(true)
                                        "
                                        >展开所有</el-button
                                    >
                                    <el-button
                                        type="info"
                                        size="mini"
                                        @click="$refs.table.clearTreeExpand()"
                                        >收起所有</el-button
                                    >
                                </el-col>
                                <el-col :span="1">
                                    <vxe-button
                                        @click="getList(condition.company)"
                                        >刷新</vxe-button
                                    >
                                </el-col>
                            </el-row>
                        </template>
                    </vxe-toolbar>
                    <vxe-table
                        ref="table"
                        border
                        resizable
                        :auto-resize="true"
                        :header-row-style="headerStyle"
                        :row-style="rowStyle"
                        align="center"
                        class="vxe-table-element"
                        height="700"
                        row-id="id"
                        :tree-config="{
                            children: 'sons',
                            expandAll: true,
                            indent: 15,
                        }"
                        :data="dept"
                        :loading="loading2"
                    >
                        <!--            <vxe-table-column type="checkbox" width="60" />-->
                        <vxe-table-column type="seq" width="60" />
                        <vxe-table-column
                            field="departName"
                            title="部门名称"
                            align="left"
                            tree-node
                        />
                        <vxe-table-column
                            field="companyName"
                            title="公司名称"
                            align="left"
                        />
                        <vxe-table-column field="" title="操作">
                            <template v-slot="{ row, rowIndex }">
                                <el-dropdown size="small" type="primary">
                                    <el-button size="small" type="primary">
                                        操作<i
                                            class="el-icon-arrow-down el-icon--right"
                                        />
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:org:depart:add'
                                                )
                                            "
                                            icon="el-icon-plus"
                                            @click.native="
                                                openDialog(
                                                    {
                                                        title: '添加下级',
                                                        addOr: 'addSon',
                                                    },
                                                    row
                                                )
                                            "
                                        >
                                            添加
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:org:depart:edit'
                                                )
                                            "
                                            icon="el-icon-edit"
                                            @click.native="
                                                openDialog(
                                                    {
                                                        title: '修改名称',
                                                        addOr: 'upd',
                                                    },
                                                    row
                                                )
                                            "
                                        >
                                            修改
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            v-if="
                                                $hasBtn(
                                                    'sys:permissions:org:depart:delete'
                                                )
                                            "
                                            icon="el-icon-delete"
                                            @click.native="
                                                remove({ del: 'single' }, row)
                                            "
                                            >删除
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </vxe-table-column>
                    </vxe-table>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { getAllCompanies } from "@/api/company";
import { getDeptByCompany, detDept } from "@/api/dept";
import { transData } from "@/utils/handleJson";
import Operate from "./Operate";
import { company, depart } from "@/api";
export default {
    name: "Index",
    components: {
        Operate,
    },
    data() {
        return {
            loading: false,
            loading2: false,
            condition: {
                dept: {},
                companyId: this.$admin ? "" : this.$companyId,
                search: "",
                search2: "",
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                company: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                show: false,
            },
            dept: [],
            company: [],
            loginInfo: {},
        };
    },
    created() {
        this.getCompany();
    },
    methods: {
        rowStyle,
        headerStyle,
        getCompany() {
            this.loading = true;
            this.$axiosReq(
                company,
                null,
                {
                    pageSize: 100000,
                },
                "get"
            )
                .then((res) => {
                    this.company = res.data.content;
                    this.condition.companyId = res.data.content[0].id;
                    this.getList();
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        changeCondition() {
            this.getList();
        },
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        searchInput() {
            this.getList();
        },
        getList() {
            this.loading2 = true;
            this.$axiosReq(
                depart,
                null,
                {
                    companyId: this.condition.companyId,
                    seachName: this.condition.search,
                    type: 1,
                },
                "get"
            )
                .then((res) => {
                    this.dept = res.data;
                })
                .finally((res) => {
                    this.loading2 = false;
                });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
        remove(info, row) {
            const id = [];
            if (info.del === "batch") {
                const arr = this.$refs.dept.getCheckboxRecords();
                const arr1 = this.$refs.dept.getCheckboxReserveRecords();
                const batchDel = arr.concat(arr1);
                for (const i of batchDel) {
                    id.push(i.dept_id);
                }
                if (id.length === 0) {
                    this.$message.error("请勾选！！！");
                    return;
                }
            } else {
                id.push(row.dept_id);
            }

            this.$confirm("确认删除吗！！！", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$axiosReq(
                        depart + "/" + row.id,
                        null,
                        null,
                        "delete"
                    ).then((res) => {
                        this.$message.success(res.msg);
                        this.$refs.table.clearCheckboxRow();
                        this.$refs.table.clearCheckboxReserve();
                        this.getList(this.condition.company);
                    });
                })
                .catch(() => {
                    this.$message.info("取消了删除！！！");
                });
        },
        refreshDialog() {
            this.dialog.refresh = false;
        },
    },
};
</script>

<style scoped></style>
